import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Home.vue';
import Message from '../views/Message.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    props: true,
    meta:{
      title:'Home'
    }
  },
  {
    path: '/message',
    name: 'message',
    component: Message,
    props: true,
    meta:{
      title:'Message'
    }
  },
  
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// router.beforeEach((to,from,next)=>{
//   document.title = `${to.meta.title} | FireBlog`;
//   next();
// });

export default router
