<template>
  <section class="form-main-wrapper" id="FormMain">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="download-btn text-end">
            <button @click="downloadPdf">DOWNLOAD SCHOOL & COLLEGE FACULTY POSTS</button>
          </div>
        </div>
        <div class="col-lg-12 mt-4">
          <div class="form-title mb-5">
            <h2>online job application Form</h2>
          </div>
        </div>
      </div>
      <!-- Form Row Start Here -->
      <div class="row">
        <div class="col-lg-12">
          <form @submit.prevent="JobForm">
            <div class="row">
              <!-- Name -->
              <div class="col-lg-3 mb-4">
                <div class="singleInput-Wrapper">
                  <label class="d-block">Name<sup>*</sup></label>
                  <div class="position-relative">
                    <input
                      type="text"
                      id="CandidateName"
                      placeholder="Enter Name"
                      v-model="CandidateName"
                      class="form-control"
                      :class="{ error: !isCandidateNameValid }"
                      @blur="validateCandidateName"
                      @keydown="validateInputs"
                    />
                    <div v-if="!isCandidateNameValid">
                      <label class="error-label"
                        >Please Enter Candidate's Name</label
                      >
                    </div>
                    <div
                      v-if="showCandidateNameErrorMessage"
                      class="error-label"
                    >
                      Numeric & Special Characters are not allowed.
                    </div>
                    <div class="input-icons">
                      <span><img src="../assets/avatar.png" alt="" /></span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Father Name -->
              <div class="col-lg-3 mb-4">
                <div class="singleInput-Wrapper">
                  <label class="d-block">Father's Name<sup>*</sup></label>
                  <div class="position-relative">
                    <input
                      type="text"
                      id="FatherName"
                      placeholder="Enter Father's Name"
                      class="form-control"
                      :class="{ error: !isFatherNameValid }"
                      v-model="FatherName"
                      @blur="validateFatherName"
                      @keydown="validateInputs"
                    />
                    <div v-if="!isFatherNameValid">
                      <label class="error-label"
                        >Please Enter Father Name</label
                      >
                    </div>
                    <div v-if="showFatherNameErrorMessage" class="error-label">
                      Numeric & Special Characters are not allowed.
                    </div>
                    <div class="input-icons">
                      <span><img src="../assets/man.png" alt="" /></span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- CNIC No -->
              <div class="col-lg-3 mb-4">
                <div class="singleInput-Wrapper">
                  <label class="d-block"
                    >CNIC No<sup>*</sup>
                    <small>(exp: 1234567891234)</small></label
                  >
                  <div class="position-relative">
                    <input
                      type="text"
                      :class="{ error: !isCNICValid }"
                      v-model="CNIC"
                      @blur="validateCNIC"
                      placeholder="Enter CNIC No"
                      class="form-control"
                      :maxlength="13"
                      @keydown="onlyNumeric"
                    />
                    <div v-if="!isCNICValid">
                      <label class="error-label"
                        >Please Enter CNIC Without (" - ")</label
                      >
                    </div>
                    <div class="input-icons">
                      <span><img src="../assets/id-card.png" alt="" /></span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Age -->
              <div class="col-lg-3 mb-4">
                <div class="singleInput-Wrapper">
                  <label class="d-block">Age<sup>*</sup></label>
                  <div class="position-relative">
                    <input
                      type="text"
                      placeholder="Enter Your Age"
                      class="form-control"
                      :class="{ error: !isAgeValid }"
                      v-model="Age"
                      @blur="validateAge"
                      :maxlength="3"
                      @keydown="onlyNumeric"
                    />
                    <div v-if="!isAgeValid">
                      <label class="error-label">Please Enter Your Age</label>
                    </div>
                    <div class="input-icons">
                      <span><img src="../assets/growth.png" alt="" /></span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Date Of Birth -->
              <div class="col-lg-3 mb-4">
                <div class="singleInput-Wrapper">
                  <label class="d-block">Date of Birth</label>
                  <div class="position-relative">
                    <input
                      type="date"
                      class="form-control"
                      v-model="DOB"
                      :class="{ error: !isDOBValid }"
                      @blur="validateDOB"
                    />
                    <div v-if="!isDOBValid">
                      <label class="error-label"
                        >Please Enter Your Date of Birth</label
                      >
                    </div>
                    <div class="input-icons">
                      <span><img src="../assets/birthday.png" alt="" /></span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Gender -->
              <div class="col-lg-3 mb-4">
                <div class="singleInput-Wrapper">
                  <label class="d-block">Gender<sup>*</sup></label>
                  <div class="position-relative">
                    <select
                      class="form-select"
                      v-model="Gender"
                      :class="{ error: !isGenderValid }"
                      @blur="validateGender"
                    >
                      <option value="">Choose...</option>
                      <option
                        v-for="option in GenderOptions"
                        :key="option.label"
                        :value="option.value"
                      >
                        {{ option.label }}
                      </option>
                    </select>
                    <div v-if="!isGenderValid">
                      <label class="error-label"
                        >Please Select Your Gender</label
                      >
                    </div>
                    <div class="input-icons selct-icon">
                      <span
                        ><img src="../assets/gender-fluid.png" alt=""
                      /></span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Religion -->
              <div class="col-lg-3 mb-4">
                <div class="singleInput-Wrapper">
                  <label class="d-block">Religion<sup>*</sup></label>
                  <div class="position-relative">
                    <select
                      class="form-select"
                      v-model="Religion"
                      :class="{ error: !isReligionValid }"
                      @blur="validateReligion"
                    >
                      <option value="">Choose...</option>
                      <option
                        v-for="option in ReligionOptions"
                        :key="option.label"
                        :value="option.value"
                      >
                        {{ option.label }}
                      </option>
                    </select>
                    <div v-if="!isReligionValid">
                      <label class="error-label"
                        >Please Choose Your Religion</label
                      >
                    </div>
                    <div class="input-icons selct-icon">
                      <span><img src="../assets/mosque.png" alt="" /></span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Domicile District -->
              <div class="col-lg-3 mb-4">
                <div class="singleInput-Wrapper">
                  <label class="d-block">Domicile District<sup>*</sup></label>
                  <div class="position-relative">
                    <select
                      class="form-select"
                      v-model="DomicileDistrict"
                      :class="{ error: !isDomicileDistrictValid }"
                      @blur="validateDomicileDistrict"
                    >
                      <option value="">Choose...</option>
                      <option
                        v-for="option in DomicileDistrictOptions"
                        :key="option.label"
                        :value="option.value"
                      >
                        {{ option.label }}
                      </option>
                    </select>
                    <div v-if="!isDomicileDistrictValid">
                      <label class="error-label"
                        >Please Choose Your Domicile District</label
                      >
                    </div>
                    <div class="input-icons selct-icon">
                      <span
                        ><img src="../assets/certificate.png" alt=""
                      /></span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Post Applied For -->
              <div class="col-lg-3 mb-4">
                <div class="singleInput-Wrapper">
                  <label class="d-block">Post Applied For<sup>*</sup></label>
                  <div class="position-relative">
                    <select
                      class="form-select"
                      v-model="PostAppliedFor"
                      :class="{ error: !isPostAppliedForValid }"
                      @blur="validatePostAppliedFor"
                    >
                      <option value="">Choose...</option>
                      <option
                        v-for="option in JobPostOptions"
                        :key="option.label"
                        :value="option.value"
                      >
                        {{ option.label }}
                      </option>
                    </select>
                    <div v-if="!isPostAppliedForValid">
                      <label class="error-label"
                        >Please Choose Post Applied For</label
                      >
                    </div>
                    <div class="input-icons selct-icon">
                      <span><img src="../assets/suitcase.png" alt="" /></span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Job In Which Campus Of ICMS -->
              <div class="col-lg-3 mb-4">
                <div class="singleInput-Wrapper">
                  <label class="d-block"
                    >Job In Which Campus Of ICMS<sup>*</sup></label
                  >
                  <div class="position-relative">
                    <select
                      class="form-select"
                      v-model="JobCampus"
                      :class="{ error: !isJobCampusValid }"
                      @blur="validateJobCampus"
                    >
                      <option value="">Choose...</option>
                      <option
                        v-for="option in JobCampusOptions"
                        :key="option.label"
                        :value="option.value"
                      >
                        {{ option.label }}
                      </option>
                    </select>
                    <div v-if="!isJobCampusValid">
                      <label class="error-label"
                        >Please Choose Your Campus</label
                      >
                    </div>
                    <div class="input-icons selct-icon">
                      <span><img src="../assets/school.png" alt="" /></span>
                    </div>
                  </div>
                </div>
              </div>

              
               <!-- Appying In -->
               <div class="col-lg-3 mb-4">
                <div class="singleInput-Wrapper">
                  <label class="d-block"
                    >Applying In For<sup>*</sup></label
                  >
                  <div class="position-relative">
                    <select
                      class="form-select"
                      v-model="ApplyingIn"
                      :class="{ error: !isApplyingInValid }"
                      @blur="validateApplyingIn"
                    >
                      <option value="">Choose...</option>
                      <option
                        v-for="option in ApplyingInOptions"
                        :key="option.label"
                        :value="option.value"
                      >
                        {{ option.label }}
                      </option>
                    </select>
                    <div v-if="!isApplyingInValid">
                      <label class="error-label"
                        >Please Choose Your Applying Purpose</label
                      >
                    </div>
                    <div class="input-icons selct-icon">
                      <span><img src="../assets/web-browser.png" alt="" /></span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Subject -->
              <div class="col-lg-3 mb-4">
                <div class="singleInput-Wrapper">
                  <label class="d-block"
                    >Subject<sup>*</sup></label
                  >
                  <div class="position-relative">
                    <select
                      class="form-select"
                      v-model="Subject"
                      :class="{ error: !isSubjectValid }"
                      @blur="validateSubject"
                    >
                      <option value="">Choose...</option>
                      <option
                        v-for="option in SubjectOptions"
                        :key="option.label"
                        :value="option.value"
                      >
                        {{ option.label }}
                      </option>
                    </select>
                    <div v-if="!isSubjectValid">
                      <label class="error-label"
                        >Please Choose Your Subject</label
                      >
                    </div>
                    <div class="input-icons selct-icon">
                      <span><img src="../assets/text-books.png" alt="" /></span>
                    </div>
                  </div>
                </div>
              </div>


              <!-- Mobile Number -->
              <div class="col-lg-3 mb-4">
                <div class="singleInput-Wrapper">
                  <label class="d-block">Mobile Number<sup>*</sup></label>
                  <div class="position-relative">
                    <input
                      type="tel"
                      id="MobileNumber"
                      v-model="MobileNumber"
                      placeholder="Enter Mobile Number"
                      class="form-control"
                      :class="{ error: !isMobileNumberValid }"
                      @blur="validateMobileNumber"
                      :maxlength="11"
                      @keydown="onlyNumeric"
                    />
                    <div v-if="!isMobileNumberValid">
                      <label class="error-label"
                        >Please Enter a Valid 11-digit Phone Number</label
                      >
                    </div>
                    <div class="input-icons">
                      <span><img src="../assets/call.png" alt="" /></span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- WhatsApp Number -->
              <div class="col-lg-3 mb-4">
                <div class="singleInput-Wrapper">
                  <label class="d-block">WhatsApp Number</label>
                  <div class="position-relative">
                    <input
                      type="tel"
                      id="WhatsAppNo"
                      v-model="WhatsAppNo"
                      placeholder="Enter WhatsApp Number"
                      class="form-control"
                      :class="{ error: !isWhatsAppNoValid }"
                      @blur="validateWhatsAppNo"
                      :maxlength="11"
                      @keydown="onlyNumeric"
                    />
                    <div v-if="!isWhatsAppNoValid">
                      <label class="error-label"
                        >Please Enter a Valid 11-digit Whatsapp Number</label
                      >
                    </div>
                    <div class="input-icons">
                      <span><img src="../assets/whatsapp.png" alt="" /></span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Qualification -->
              <div class="col-lg-3 mb-4">
                <div class="singleInput-Wrapper">
                  <label class="d-block">Qualification<sup>*</sup></label>
                  <div class="position-relative">
                    <select
                      class="form-select"
                      v-model="Qualification"
                      :class="{ error: !isQualificationValid }"
                      @blur="validateQualification"
                    >
                      <option value="">Choose...</option>
                      <option
                        v-for="option in JobQualificationOptions"
                        :key="option.label"
                        :value="option.value"
                      >
                        {{ option.label }}
                      </option>
                    </select>
                    <div v-if="!isQualificationValid">
                      <label class="error-label"
                        >Please Choose Qualification</label
                      >
                    </div>
                    <div class="input-icons selct-icon">
                      <span><img src="../assets/score.png" alt="" /></span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Experience  -->
              <div class="col-lg-3 mb-4">
                <div class="singleInput-Wrapper">
                  <label class="d-block">Year Of Experience <sup>*</sup></label>
                  <div class="position-relative">
                    <input
                      type="text"
                      v-model="Experience"
                      placeholder="Enter Your Experience"
                      class="form-control"
                      :class="{ error: !isExperienceValid }"
                      @blur="validateExperience"
                      :maxlength="4"
                      @keydown="onlyNumeric"
                    />
                    <div v-if="!isExperienceValid">
                      <label class="error-label"
                        >Please Enter Your Experience</label
                      >
                    </div>
                    <div class="input-icons adjustment">
                      <span><img src="../assets/customer.png" alt="" /></span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Current Job  -->
              <div class="col-lg-3 mb-4">
                <div class="singleInput-Wrapper">
                  <label class="d-block">Current Job <sup>*</sup></label>
                  <div class="position-relative">
                    <input
                      type="text"
                      v-model="CurrentJob"
                      placeholder="Enter Your Current Job"
                      class="form-control"
                      :class="{ error: !isCurrentJobValid }"
                      @blur="validateCurrentJob"
                    />
                    <div v-if="!isCurrentJobValid">
                      <label class="error-label"
                        >Please Enter Your Current Job</label
                      >
                    </div>
                    <div class="input-icons">
                      <span
                        ><img src="../assets/businessman.png" alt=""
                      /></span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Current Address  -->
              <div class="col-lg-9 mb-4">
                <div class="singleInput-Wrapper">
                  <label class="d-block">Current Address <sup>*</sup></label>
                  <div class="position-relative">
                    <input
                      type="text"
                      v-model="CurrentAddress"
                      placeholder="Enter Your Current Address"
                      class="form-control"
                      :class="{ error: !isCurrentAddressValid }"
                      @blur="validateCurrentAddress"
                    />
                    <div v-if="!isCurrentAddressValid">
                      <label class="error-label"
                        >Please Enter Your Current Address</label
                      >
                    </div>
                    <div class="input-icons">
                      <span><img src="../assets/map.png" alt="" /></span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-12 mb-4 mt-4">
                <div class="row justify-content-center">
                  <div class="col-lg-3">
                    <div class="submitbtn">
                      <button type="submit">Submit Form</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- Form Row End's Here -->
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "Home-view",

  data() {
    return {


      //   Name
      CandidateName: "",
      showCandidateNameErrorMessage: false,
      isCandidateNameValid: true,

      // Father Name
      FatherName: "",
      isFatherNameValid: true,
      showFatherNameErrorMessage: false,

      // CNIC No
      CNIC: "",
      isCNICValid: true,

      //Age
      Age: "",
      isAgeValid: true,

      // Date Of Birth
      DOB: "",
      isDOBValid: true,

      // Gender
      Gender: "",
      isGenderValid: true,

      // Religion
      Religion: "",
      isReligionValid: true,

      // Domicile District
      DomicileDistrict: "",
      isDomicileDistrictValid: true,

      // Post Applied For
      PostAppliedFor: "",
      isPostAppliedForValid: true,

      // Subject
      Subject: "",
      isSubjectValid: true,

      // Applying in 
      ApplyingIn: "",
      isApplyingInValid: true,

      // Job Campus
      JobCampus: "",
      isJobCampusValid: true,

      // Mobile Number
      MobileNumber: "",
      isMobileNumberValid: true,

      // Whatsapp Number
      WhatsAppNo: "",
      isWhatsAppNoValid: true,

      // Qualification
      Qualification: "",
      isQualificationValid: true,

      // Experience
      Experience: "",
      isExperienceValid: true,

      // Current Job
      CurrentJob: "",
      isCurrentJobValid: true,

      // Current Address
      CurrentAddress: "",
      isCurrentAddressValid: true,

      GenderOptions: [
        { label: "Male", value: "male" },
        { label: "Female", value: "female" },
      ],
      ApplyingInOptions: [
        { label: "School", value: "school" },
        { label: "College", value: "college" },
      ],
      ReligionOptions: [
        { label: "Islam", value: "islam" },
        { label: "Other", value: "other" },
      ],
      DomicileDistrictOptions: [
        { label: "Peshawar", value: "peshawar" },
        { label: "Nowshera", value: "nowshera" },
        { label: "Charsadda", value: "charsadda" },
        { label: "Chitral", value: "chitral" },
        { label: "Upper Dir", value: "upper dir" },
        { label: "Lower Dir", value: "lower dir" },
        { label: "Malakand", value: "malakand" },
        { label: "Buner", value: "buner" },
        { label: "Shangla", value: "shangla" },
        { label: "Kohistan", value: "kohistan" },
        { label: "Batagram", value: "batagram" },
        { label: "Mansehra", value: "mansehra" },
        { label: "Abbottabad", value: "abbottabad" },
        { label: "Haripur", value: "haripur" },
        { label: "Swabi", value: "swabi" },
        { label: "Mardan", value: "mardan" },
        { label: "Kohat", value: "kohat" },
        { label: "Hangu", value: "hangu" },
        { label: "Bannu", value: "bannu" },
        { label: "Laki Marwat", value: "laki marwat" },
        { label: "Tank", value: "Tank" },
        { label: "D.I Khan", value: "D.I khan" },
        { label: "Khurram Agency", value: "khurram agency" },
        { label: "Khyber Agency", value: "khyber agency" },
        { label: "South Waziristan", value: "south waziristan" },
        { label: "North Waziristan", value: "north waziristan" },
        { label: "Mohamand Agency", value: "Mohamand agency" },
        { label: "Bajur Agency", value: "bajur agency" },
        { label: "Orakzai Agency", value: "orakzai agency" },
        { label: "FR Areas", value: "FR areas" },
        { label: "Other", value: "other" },
      ],
      JobCampusOptions: [
        { label: "Peshawar Campus", value: "Peshawar Campus" },
        { label: "Nowshera Campus", value: "Nowshera Campus" },
      ],
      JobPostOptions: [
        { label: "Vice Principal", value: "Vice Principal" },
        { label: "Academics Coordinator", value: "Academics Coordinator" },
        { label: "Administrator", value: "Administrator" },
        { label: "HR Manager", value: "HR Manager" },
        { label: "Accountant ", value: "Accountant" },
        { label: "Teachers (Play Group)", value: "Teacher (Play Group)" },
        { label: "Teachers (Nursery)", value: "Teacher (Nursery)" },
        { label: "Teachers (Kindergarden)", value: "Teacher (Kindergarden)" },
        { label: "Teachers (Class 1 to Class 5)", value: "Teacher (Class 1 to Class 5)" },
        { label: "Teachers (Class 6 to Class 10)", value: "Teacher (Class 6 to Class 10)" },
        { label: "Teachers (Nazra)", value: "Teacher (Nazra)" },
        { label: "Teachers (Martial Art)", value: "Teacher (Martial Art)" },
        { label: "Teachers (Art)", value: "Teacher (Art)" },
        { label: "PTI", value: "PTI" },
      ],
      JobQualificationOptions: [
        { label: "BS", value: "BS" },
        { label: "BA", value: "BA" },
        { label: "Bsc", value: "Bsc" },
        { label: "Master", value: "Master" },
        { label: "M Phil", value: "M Phil" },
        { label: "Others", value: "Others" }
      ],
      SubjectOptions: [
        { label: "Physics", value: "Physics" },
        { label: "Chemistry ", value: "Chemistry " },
        { label: "Bio", value: "Bio" },
        { label: "Maths", value: "Maths" },
        { label: "Pak Study", value: "Pak Study" },
        { label: "Islamic Study", value: "Islamic Study" },
        { label: "Social Study", value: "Social Study" },
        { label: "Urdu", value: "Urdu" },
        { label: "English", value: "English" },
        { label: "Computer Science ", value: "Computer Science" },
        { label: "Mutalia Quran", value: "Mutalia Quran" },
        { label: "Science ", value: "Science" },
        { label: "Botany", value: "Botany" },
        { label: "Zoology", value: "Zoology" },
      ],
      responseData: null,
    };
  },

  methods: {

    async downloadPdf() {
      const response = await fetch('/staff-required.pdf')
      const blob = await response.blob()
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'staff-required.pdf')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    validateInputs(event) {
      const keyCode = event.keyCode || event.which;
      const inputField = event.target.getAttribute("id");

      // Allow backspace and delete keys
      if (keyCode === 8 || keyCode === 46) {
        return;
      }

      // Block numeric keys, special characters, and numeric pad keys
      if (
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        (keyCode >= 186 && keyCode <= 192) ||
        (keyCode >= 219 && keyCode <= 222)
      ) {
        event.preventDefault();
        if (inputField === "CandidateName") {
          this.showCandidateNameErrorMessage = true;
          return;
        }
        if (inputField === "FatherName") {
          this.showFatherNameErrorMessage = true;
          return;
        }
      }
      // Allow alphabetic characters and spaces
      if (
        (keyCode >= 65 && keyCode <= 90) ||
        (keyCode >= 97 && keyCode <= 122) ||
        keyCode === 32
      ) {
        if (inputField === "CandidateName") {
          this.showCandidateNameErrorMessage = false;
          return;
        }
        if (inputField === "FatherName") {
          this.showFatherNameErrorMessage = false;
          return;
        }
      }
      // Prevent any other keys from being entered
      // event.preventDefault();
    },
    onlyNumeric(event) {
      const keyCode = event.keyCode || event.which;

      if (
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        keyCode === 9 ||
        keyCode === 8
      ) {
        return true;
      }
      event.preventDefault();
      return false;
    },
    validateCandidateName() {
      this.isCandidateNameValid = !!this.CandidateName;
    },
    validateFatherName() {
      this.isFatherNameValid = !!this.FatherName;
    },
    validateCNIC() {
      this.isCNICValid = !!this.CNIC;
    },
    validateAge() {
      this.isAgeValid = !!this.Age;
    },
    validateDOB() {
      this.isDOBValid = !!this.DOB;
    },
    validateGender() {
      this.isGenderValid = !!this.Gender;
    },
    validateReligion() {
      this.isReligionValid = !!this.Religion;
    },
    validateDomicileDistrict() {
      this.isDomicileDistrictValid = !!this.DomicileDistrict;
    },
    validatePostAppliedFor() {
      this.isPostAppliedForValid = !!this.PostAppliedFor;
    },
    validateJobCampus() {
      this.isJobCampusValid = !!this.JobCampus;
    },
    validateMobileNumber() {
      // this.isMobileNumberValid = /^[0-9]{11}$/.test(this.MobileNumber);
      this.isMobileNumberValid = !!this.MobileNumber;
    },
    validateWhatsAppNo() {
      // this.isWhatsAppNoValid = /^[0-9]{11}$/.test(this.WhatsAppNo);
      this.isWhatsAppNoValid = !!this.WhatsAppNo;
    },
    validateQualification() {
      this.isQualificationValid = !!this.Qualification;
    },
    validateExperience() {
      this.isExperienceValid = !!this.Experience;
    },
    validateCurrentJob() {
      this.isCurrentJobValid = !!this.CurrentJob;
    },
    validateCurrentAddress() {
      this.isCurrentAddressValid = !!this.CurrentAddress;
    },
    validateSubject() {
      this.isSubjectValid = !!this.Subject;
    },
    validateApplyingIn() {
      this.isApplyingInValid = !!this.ApplyingIn;
    },

    formReset() {
      (this.CandidateName = ""),
        (this.FatherName = ""),
        (this.CNIC = ""),
        (this.Age = ""),
        (this.DOB = ""),
        (this.Gender = ""),
        (this.Religion = ""),
        (this.DomicileDistrict = ""),
        (this.PostAppliedFor = ""),
        (this.JobCampus = ""),
        (this.MobileNumber = ""),
        (this.WhatsAppNo = ""),
        (this.Qualification = ""),
        (this.Experience = ""),
        (this.CurrentJob = ""),
        (this.CurrentAddress = "");
        (this.Subject = "");
        (this.ApplyingIn = "");
    },
    JobForm() {
      this.validateCandidateName();
      this.validateFatherName();
      this.validateCNIC();
      this.validateAge();
      this.validateDOB();
      this.validateGender();
      this.validateReligion();
      this.validateDomicileDistrict();
      this.validatePostAppliedFor();
      this.validateJobCampus();
      this.validateMobileNumber();
      this.validateWhatsAppNo();
      this.validateQualification();
      this.validateExperience();
      this.validateCurrentJob();
      this.validateCurrentAddress();
      this.validateSubject();
      this.validateApplyingIn();

      if (
        !this.isCandidateNameValid ||
        !this.isFatherNameValid ||
        !this.isCNICValid ||
        !this.isAgeValid ||
        !this.isDOBValid ||
        !this.isGenderValid ||
        !this.isReligionValid ||
        !this.isDomicileDistrictValid ||
        !this.isPostAppliedForValid ||
        !this.isJobCampusValid ||
        !this.MobileNumber ||
        !this.isWhatsAppNoValid ||
        !this.isQualificationValid ||
        !this.isExperienceValid ||
        !this.isCurrentJobValid ||
        !this.isCurrentAddressValid ||
        !this.isSubjectValid ||
        !this.isApplyingInValid
      ) {
        return;
      } else {
        var model = {
          CandidateName: this.CandidateName,
          FatherName: this.FatherName,
          CNIC: this.CNIC,
          Age: this.Age,
          DOB: this.DOB,
          Gender: this.Gender,
          Religion: this.Religion,
          DomicileDistrict: this.DomicileDistrict,
          PostAppliedFor: this.PostAppliedFor,
          JobCampus: this.JobCampus,
          MobileNumber: this.MobileNumber,
          WhatsAppNo: this.WhatsAppNo,
          Qualification: this.Qualification,
          Experience: this.Experience,
          CurrentJob: this.CurrentJob,
          CurrentAddress: this.CurrentAddress,
          Subject: this.Subject,
          ApplyingIn: this.ApplyingIn,
        };
        console.log(model);
        const auth = {
          username: "icms",
          password: "icms@Cyberasol",
        };
        const headers = {
          "Content-Type": "application/json",
        };
        axios
          .post(
            "https://icms.cyberasol.com/Api/Api/InquiryWeb/AddOnlineJobApplication",
            model,
            {
              auth: auth,
              headers: headers,
            }
          )
          .then((response) => {
            if (response.status === 200) {
              console.log(response.data);
              this.responseData = response.data;
              this.formReset();
              this.$router
                .push({
                  path: "/message",
                  query: { response: this.responseData },
                })
                .then(() => {
                  window.scrollTo(100, 600);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style>
.error {
  color: red;
}
.submitbtn button {
  width: 100%;
  padding: 14px 0px;
  border: none;
  text-transform: uppercase;
  border-radius: 0px;
  font-weight: 700;
  background: #003e78;
  color: #fff;
  transition: 0.3s ease-in-out;
}
.download-btn button {
  padding: 14px 30px;
  border: none;
  text-transform: uppercase;
  border-radius: 0px;
  font-weight: 700;
  background: #003e78;
  color: #fff;
  transition: 0.3s ease-in-out;
}
.download-btn button:hover {
  border-radius: 5px;
  -webkit-box-shadow: 0px 1px 22px -2px rgba(0, 62, 120, 0.48);
  box-shadow: 0px 1px 22px -2px rgba(0, 62, 120, 0.48);
}
.submitbtn button:hover {
  border-radius: 5px;
  -webkit-box-shadow: 0px 1px 22px -2px rgba(0, 62, 120, 0.48);
  box-shadow: 0px 1px 22px -2px rgba(0, 62, 120, 0.48);
}
.form-title {
  text-align: center;
}
.form-title h2 {
  text-transform: capitalize;
  font-weight: 900;
  font-size: 30px;
  line-height: 42px;
}
.form-main-wrapper {
  padding: 70px 0px;
}
.singleInput-Wrapper label {
  text-transform: uppercase;
  margin-bottom: 8px;
  font-weight: 700;
  letter-spacing: 0.03em;
}
.singleInput-Wrapper label sup {
  color: red;
  padding-left: 5px;
}
.singleInput-Wrapper input,
.singleInput-Wrapper select,
.singleInput-Wrapper textarea {
  padding: 12px 10px 12px 60px;
  /* background: rgba(0, 62, 120, 0.06); */
  font-weight: 600;
  width: 100%;
}
.singleInput-Wrapper input::placeholder,
.singleInput-Wrapper select::placeholder,
.singleInput-Wrapper textarea::placeholder {
  font-size: 15px;
  font-weight: 400;
}
.singleInput-Wrapper input:focus,
.singleInput-Wrapper select:focus,
.singleInput-Wrapper textarea:focus {
  box-shadow: none;
  border-color: #003e78;
  /* background: rgba(0, 62, 120, 0.1); */
}
.input-icons {
  position: absolute;
  top: 1px;
  left: 0.8px;
  width: 52px;
  height: 48px;
  /* background: #fff; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.selct-icon {
  top: 1px;
  left: 0.8px;
  width: 52px;
  height: 48px;
}
.input-icons.adjustment {
  top: 1px;
  height: 48px;
}
.input-icons img {
  width: 30px;
  height: 30px;
}
.note-wrapper h2 {
  font-size: 24px;
  font-weight: 900;
  text-transform: uppercase;
}
.note-wrapper h4 {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}
.error-label {
  color: red;
  text-transform: capitalize !important;
  font-weight: 400 !important;
  font-size: 13px;
}
@media (min-width: 320px) and (max-width: 767px) {
  .top-info-wrapper {
    padding: 5px 0px;
  }
  .info-wrapper {
    text-align: center;
  }
  .media-link.text-end {
    text-align: center !important;
  }
  .hide-mobile {
    display: none;
  }
  .single-info-wrapper {
    margin-top: 10px;
  }
  .inner-content h2 {
    font-size: 20px;
  }
  .inner-content h1 {
    font-size: 40px;
  }
  .footer-top {
    padding-top: 300px !important;
  }
  .rs-footer
    .footer-contact-desc
    div[class*="col-"]
    + div[class*="col-"]
    .contact-inner {
    border-left: none !important;
  }
  .rs-footer
    .footer-contact-desc
    div[class*="col-"]
    + div[class*="col-"]
    .contact-inner:before {
    content: none !important;
  }
  .rs-footer
    .footer-contact-desc
    div[class*="col-"]
    + div[class*="col-"]
    .contact-inner::after {
    content: none !important;
  }
  .footer-media-links.text-end {
    text-align: center !important;
  }
}
</style>
