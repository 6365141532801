<template>
  <section>
    <!-- Footer Start -->
    <footer id="rs-footer" class="bg3 rs-footer">
      <div class="container">
        <!-- Footer Address -->
        <div>
          <div class="row footer-contact-desc">
            <div class="col-md-12">
              <div class="contact-inner">
                <i class="fa fa-map-marker"></i>
                <h4 class="contact-title">Address</h4>
                <p class="contact-desc">Opposite Peshawar Board,Jamrud Road, Peshawar</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Footer Top -->
      <div class="footer-top">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
              <div class="about-widget">
                <img src="../assets/logo-footer.png" alt="Footer Logo" />
                <p>Education with social responsibility.</p>
                <p>The largest educational network in Pakistan.</p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="footer-media-links text-end">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/ICMSChairman/"
                      ><i class="fa fa-facebook"></i
                    ></a>
                  </li>
                  <li>
                    <a href="https://twitter.com/ICMSChairman"
                      ><i class="fa fa-twitter"></i
                    ></a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UCIMD0HHE9L97RqpZ3jfWG8w/featured"
                      ><i class="fa fa-youtube"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Footer Bottom -->
      <div class="footer-bottom">
        <div class="container">
          <div class="copyright">
            <p>
              © 2023 <a href="https://icms.edu.pk/">ICUM</a>. All Rights
              Reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
    <!-- Footer End -->

    
  </section>
</template>

<script>
export default {
  name: "footer-view",
  setup() {},
};
</script>

<style scoped>
/* ------------------------------------
    37. Rs Footer
---------------------------------------*/
.rs-footer {
  color: #e8e8e8;
  margin-top: 98px;
}
.footer-media-links ul {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
}
.footer-media-links ul li {
  display: inline-block;
  margin-right: 20px;
}
.footer-media-links ul li a {
  font-size: 40px;
  color: #fff;
  transition: all 0.3s ease-in-out;
}
.footer-media-links ul li a:hover {
  color: #fbe325;
}
.footer-media-links ul li:last-child {
  margin-right: 0px;
}

.bg3 {
  background-image: url("../assets/counter-bg.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center top;
}
.rs-footer .footer-title {
  margin-bottom: 40px;
  padding-bottom: 5px;
  color: #ffffff;
  font-size: 16px;
  position: relative;
  font-weight: 600;
}
.rs-footer .footer-title:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  height: 1px;
  width: 50px;
  background-color: #ff3115;
}
.rs-footer .container {
  position: relative;
}
.rs-footer .footer-contact-desc {
  margin: 0;
  background: #003e78;
  text-align: center;
  padding: 35px;
  position: absolute;
  left: 0;
  right: 0;
  margin: -100px auto 0;
  z-index: 111;
  border-radius: 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
}
.rs-footer
  .footer-contact-desc
  div[class*="col-"]
  + div[class*="col-"]
  .contact-inner {
  border-left: 1px solid #e2e2e2;
}
.rs-footer
  .footer-contact-desc
  div[class*="col-"]
  + div[class*="col-"]
  .contact-inner:before,
.rs-footer
  .footer-contact-desc
  div[class*="col-"]
  + div[class*="col-"]
  .contact-inner:after {
  content: "";
  position: absolute;
  height: calc(100% - 40px);
  width: 1px;
  background-color: #e2e2e2;
  top: 50%;
  transform: translateY(-50%);
}
.rs-footer
  .footer-contact-desc
  div[class*="col-"]
  + div[class*="col-"]
  .contact-inner:before {
  left: 3px;
}
.rs-footer
  .footer-contact-desc
  div[class*="col-"]
  + div[class*="col-"]
  .contact-inner:after {
  left: -5px;
}
.rs-footer .footer-contact-desc .contact-inner {
  position: relative;
}
.rs-footer .footer-contact-desc .contact-inner i {
  font-size: 28px;
  margin-bottom: 12px;
  color: #fbe325;
}
.rs-footer .footer-contact-desc .contact-inner .contact-title {
  margin-bottom: 10px;
  color: #ffffff;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
}
.rs-footer .footer-contact-desc .contact-inner .contact-desc {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0;
  font-size: 15px;
}
.rs-footer .footer-top {
  padding-top: 140px;
}
.rs-footer .footer-top .recent-post-widget .post-item {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
.rs-footer .footer-top .recent-post-widget .post-item .post-date {
  width: 70px;
  height: 65px;
  flex: 0 0 70px;
  text-align: center;
  float: left;
  background-color: #ff3115;
  color: #ffffff;
  margin-right: 15px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
.rs-footer .footer-top .recent-post-widget .post-item .post-date span {
  display: block;
}
.rs-footer
  .footer-top
  .recent-post-widget
  .post-item
  .post-date
  span:first-child {
  margin-top: 10px;
}
.rs-footer
  .footer-top
  .recent-post-widget
  .post-item
  .post-date
  span:last-child {
  font-size: 15px;
}
.rs-footer .footer-top .recent-post-widget .post-item .post-title {
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 0;
  font-weight: 400;
}
.rs-footer .footer-top .recent-post-widget .post-item .post-title a {
  font-family: "Montserrat", sans-serif;
  color: #e8e8e8;
}
.rs-footer .footer-top .recent-post-widget .post-item .post-title a:hover,
.rs-footer .footer-top .recent-post-widget .post-item .post-title a:focus {
  color: #bbbbbb;
}
.rs-footer .footer-top .recent-post-widget .post-item .post-category {
  font-size: 15px;
}
.rs-footer .footer-top .recent-post-widget .post-item .post-item {
  margin-top: 18px;
  padding-top: 18px;
  border-top: 1px solid rgba(102, 102, 102, 0.5);
}
.rs-footer .footer-top .sitemap-widget li {
  width: 100%;
  float: left;
  line-height: 33px;
}
.rs-footer .footer-top .sitemap-widget li a {
  color: #e8e8e8;
  display: inline-block;
  position: relative;
}
.rs-footer .footer-top .sitemap-widget li a:hover,
.rs-footer .footer-top .sitemap-widget li a:focus {
  color: #ff3115;
}
.rs-footer .footer-top .sitemap-widget li a i {
  padding-right: 10px;
}
.rs-footer .footer-top .flickr-feed li {
  display: inline-block;
  margin: 2px 3px;
  overflow: hidden;
  position: relative;
  width: 76px;
}
.rs-footer .footer-top .flickr-feed li img {
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
}
.rs-footer .footer-top .flickr-feed li:hover img {
  opacity: 0.7;
}
.rs-footer .footer-top .news-form {
  position: relative;
}
.rs-footer .footer-top .news-form input {
  background: rgba(0, 0, 0, 0);
  border: 1px solid #ff3115;
  color: #ffffff;
  height: 50px;
  outline: 0 none;
  padding: 5px 15px;
  width: 100%;
}
.rs-footer .footer-top .news-form button {
  background: #ff3115;
  border: none;
  color: #ffffff;
  font-size: 18px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
}
.rs-footer .footer-top .news-form button:hover {
  background: #e41f05;
}
.rs-footer .footer-top .about-widget img {
  margin-bottom: 25px;
}
.rs-footer .footer-share {
  text-align: center;
  margin-top: 50px;
}
.rs-footer .footer-share ul li {
  display: inline-block;
}
.rs-footer .footer-share ul li a {
  font-size: 13px;
  display: block;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  line-height: 44px;
  text-align: center;
  color: #fff;
  transition: all 0.3s ease 0s;
  background: rgba(255, 255, 255, 0.15);
}
.rs-footer .footer-share ul li a:hover {
  background-color: #ff3115;
  color: #ffffff;
}
.rs-footer .footer-share ul li + li {
  margin-left: 5px;
}
.rs-footer .footer-bottom {
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  padding: 18px 0;
  margin-top: 35px;
}
.rs-footer .footer-bottom .copyright p {
  opacity: 0.95;
  margin-bottom: 0;
  font-size: 15px;
}
.rs-footer.rs-footer-style8 .footer-top .recent-post-widget .post-item {
  padding-bottom: 15px;
  padding-top: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
.rs-footer.rs-footer-style8
  .footer-top
  .recent-post-widget
  .post-item:last-child {
  border: none;
}
.rs-footer.rs-footer-style8
  .footer-top
  .recent-post-widget
  .post-item
  .post-date {
  width: 70px;
  height: 65px;
  flex: 0 0 70px;
  text-align: center;
  color: #fff;
  margin-right: 15px;
  transition: all 0.3s ease 0s;
  background: #ff3115 !important;
  line-height: 27px;
}
.rs-footer.rs-footer-style8
  .footer-top
  .recent-post-widget
  .post-item
  .post-date
  span {
  display: block;
}
.rs-footer.rs-footer-style8
  .footer-top
  .recent-post-widget
  .post-item
  .post-date
  span:first-child {
  margin-top: 10px;
}
.rs-footer.rs-footer-style8
  .footer-top
  .recent-post-widget
  .post-item
  .post-date
  span:last-child {
  font-size: 15px;
}
.rs-footer.rs-footer-style8
  .footer-top
  .recent-post-widget
  .post-item
  .post-title {
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 0;
  font-weight: 400;
}
.rs-footer.rs-footer-style8
  .footer-top
  .recent-post-widget
  .post-item
  .post-title
  a {
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
}
.rs-footer.rs-footer-style8
  .footer-top
  .recent-post-widget
  .post-item
  .post-title
  a:hover,
.rs-footer.rs-footer-style8
  .footer-top
  .recent-post-widget
  .post-item
  .post-title
  a:focus {
  color: #ff3115 !important;
}
.rs-footer.rs-footer-style8 .footer-top .form-inner {
  position: relative;
  max-width: 280px;
}
.rs-footer.rs-footer-style8 .footer-top .form-inner:before {
  content: "\f1d9";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  text-decoration: inherit;
  position: absolute;
  right: 30px;
  top: 10px;
  color: #fff;
  pointer-events: none;
  z-index: 11;
  font-size: 20px;
}
.rs-footer.rs-footer-style8 .footer-top .form-inner input[type="email"] {
  font-size: 14px;
  padding: 12px 0 12px 16px;
  border: none;
  border-radius: 25px !important;
  height: 46px;
  position: relative;
  display: block;
  line-height: 1.428571429;
  color: #555;
  background-color: #fff;
  outline: 0;
  width: 100%;
}
.rs-footer.rs-footer-style8 .footer-top .form-inner input[type="submit"] {
  position: absolute;
  border-radius: 25px;
  top: 2px;
  right: 2px;
  background: #ff3115;
  color: #fff;
  padding: 10px 16px;
  border: 0;
  transition: 0.2s;
  font-size: 0;
  height: 42px;
  width: 74px;
  min-width: auto !important;
}
.rs-footer.rs-footer-style7 {
  position: relative;
  background: #f2f2f2;
}
.rs-footer.rs-footer-style7 .footer-top {
  padding-top: 70px !important;
}
.rs-footer.rs-footer-style7 .footer-top .footer-title {
  color: #212121;
}
.rs-footer.rs-footer-style7 .footer-top .footer-title:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  height: 1px;
  width: 50px;
  background: #ff3115;
}
.rs-footer.rs-footer-style7 .footer-top .recent-post-widget .post-item {
  padding-bottom: 15px;
  padding-top: 15px;
  border-bottom: 1px solid #e9e2e2;
}
.rs-footer.rs-footer-style7
  .footer-top
  .recent-post-widget
  .post-item:last-child {
  border: none;
}
.rs-footer.rs-footer-style7
  .footer-top
  .recent-post-widget
  .post-item
  .post-date {
  width: 70px;
  height: 65px;
  flex: 0 0 70px;
  text-align: center;
  color: #fff;
  margin-right: 15px;
  transition: all 0.3s ease 0s;
  background: #ff3115 !important;
  line-height: 27px;
}
.rs-footer.rs-footer-style7
  .footer-top
  .recent-post-widget
  .post-item
  .post-date
  span {
  display: block;
}
.rs-footer.rs-footer-style7
  .footer-top
  .recent-post-widget
  .post-item
  .post-date
  span:first-child {
  margin-top: 10px;
}
.rs-footer.rs-footer-style7
  .footer-top
  .recent-post-widget
  .post-item
  .post-date
  span:last-child {
  font-size: 15px;
}
.rs-footer.rs-footer-style7
  .footer-top
  .recent-post-widget
  .post-item
  .post-title {
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 0;
  font-weight: 400;
}
.rs-footer.rs-footer-style7
  .footer-top
  .recent-post-widget
  .post-item
  .post-title
  a {
  font-family: "Montserrat", sans-serif;
  color: #505050;
}
.rs-footer.rs-footer-style7
  .footer-top
  .recent-post-widget
  .post-item
  .post-title
  a:hover,
.rs-footer.rs-footer-style7
  .footer-top
  .recent-post-widget
  .post-item
  .post-title
  a:focus {
  color: #ff3115 !important;
}
.rs-footer.rs-footer-style7 .footer-top .sitemap-widget li {
  width: 50%;
  float: left;
  line-height: 33px;
}
.rs-footer.rs-footer-style7 .footer-top .sitemap-widget li a {
  color: #505050;
  display: inline-block;
  position: relative;
}
.rs-footer.rs-footer-style7 .footer-top .sitemap-widget li a:hover,
.rs-footer.rs-footer-style7 .footer-top .sitemap-widget li a:focus {
  color: #ff3115 !important;
}
.rs-footer.rs-footer-style7 .footer-top .sitemap-widget li a i {
  padding-right: 10px;
}
.rs-footer.rs-footer-style7 .footer-top .flickr-feed li {
  display: inline-block;
  margin: 2px 3px;
  overflow: hidden;
  position: relative;
  width: 76px;
}
.rs-footer.rs-footer-style7 .footer-top .flickr-feed li img {
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
}
.rs-footer.rs-footer-style7 .footer-top .flickr-feed li:hover img {
  opacity: 0.7;
}
.rs-footer.rs-footer-style7 .footer-top .form-inner {
  position: relative;
  max-width: 280px;
}
.rs-footer.rs-footer-style7 .footer-top .form-inner:before {
  content: "\f1d9";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  text-decoration: inherit;
  position: absolute;
  right: 30px;
  top: 10px;
  color: #fff;
  pointer-events: none;
  z-index: 11;
  font-size: 20px;
}
.rs-footer.rs-footer-style7 .footer-top .form-inner input[type="email"] {
  font-size: 14px;
  padding: 12px 0 12px 16px;
  border: none;
  border-radius: 25px !important;
  height: 46px;
  position: relative;
  display: block;
  line-height: 1.428571429;
  color: #555;
  background-color: #fff;
  outline: 0;
  width: 100%;
}
.rs-footer.rs-footer-style7 .footer-top .form-inner input[type="submit"] {
  position: absolute;
  border-radius: 25px;
  top: 2px;
  right: 2px;
  background: #ff3115;
  color: #fff;
  padding: 10px 16px;
  border: 0;
  transition: 0.2s;
  font-size: 0;
  height: 42px;
  width: 74px;
  min-width: auto !important;
}
.rs-footer.rs-footer-style7 .footer-top .about-widget img {
  margin-bottom: 25px;
}
.rs-footer.rs-footer-style7 .footer-share {
  text-align: center;
  margin-top: 50px;
}
.rs-footer.rs-footer-style7 .footer-share ul li {
  display: inline-block;
}
.rs-footer.rs-footer-style7 .footer-share ul li a {
  background: none !important;
  color: #212121 !important;
}
.rs-footer.rs-footer-style7 .footer-share ul li a:hover {
  color: #ff3115 !important;
}
.rs-footer.rs-footer-style7 .footer-share ul li li {
  margin-left: 5px;
}
.rs-footer.rs-footer-style7:before {
  background: #f2f2f2;
  transform: skewY(175deg);
  content: "";
  height: 100%;
  right: 0;
  position: absolute;
  top: -100px;
  width: 100%;
  z-index: -1;
}
.rs-footer.rs-footer-style7 .footer-contact-desc {
  padding-top: 0;
  border-bottom: 1px solid #e9e2e2;
  transform: translateY(0);
  position: static;
  border-radius: 0;
  box-shadow: none;
  margin: -100px auto 0;
  background: none;
}
.rs-footer.rs-footer-style7 .footer-contact-desc .contact-inner i:before {
  color: #ff3115;
}
.rs-footer.rs-footer-style7 .footer-contact-desc .contact-inner .contact-title {
  color: #212121;
}
.rs-footer.rs-footer-style7 .footer-contact-desc .contact-inner .contact-desc {
  color: #505050;
}
.rs-footer.rs-footer-style7
  .footer-contact-desc
  div[class*="col-"]
  + div[class*="col-"]
  .contact-inner {
  border-left: 1px solid #e2e2e2;
}
.rs-footer.rs-footer-style7
  .footer-contact-desc
  div[class*="col-"]
  + div[class*="col-"]
  .contact-inner:before,
.rs-footer.rs-footer-style7
  .footer-contact-desc
  div[class*="col-"]
  + div[class*="col-"]
  .contact-inner:after {
  content: "";
  position: absolute;
  height: calc(100% - 40px);
  width: 1px;
  background-color: #e2e2e2;
  top: 50%;
  transform: translateY(-50%);
}
.rs-footer.rs-footer-style7
  .footer-contact-desc
  div[class*="col-"]
  + div[class*="col-"]
  .contact-inner:before {
  left: 3px;
}
.rs-footer.rs-footer-style7
  .footer-contact-desc
  div[class*="col-"]
  + div[class*="col-"]
  .contact-inner:after {
  left: -5px;
}
.copyright_style7 {
  text-align: center;
  border-top: 1px solid #e9e2e2 !important;
  padding: 18px 0;
  margin-top: 35px;
  background: none !important;
}
.copyright_style7 .copyright p {
  opacity: 0.95;
  margin-bottom: 0;
  font-size: 15px;
}
.copyright_style7 .copyright p a {
  color: #ff3115;
}
.copyright_style7 .copyright p a:hover {
  color: #212121;
}
.rs-footer-2 .footer-share {
  margin-top: 20px;
}

@-webkit-keyframes rs-animation-scale-up {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.2);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}
@keyframes rs-animation-scale-up {
  0% {
    opacity: 0;
    transform: scale(0.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
