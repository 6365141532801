<template>
    <section class="responseDataWrapper">
      <div class="container">
        <div class="row">

          <div class="col-lg-12 mt-5">
            <div>
              <div class="alert alert-success text-center" role="alert">
                Your Application has been submitted : <strong>{{ dataFromFirstComponent }}</strong>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
              <div class="text-center linkback">
                  <router-link :to="{ name: 'home' }">Go Back To Home</router-link>
              </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: "message-view",
  
    data() {
      return {
        dataFromFirstComponent: null,
      };
    },
    created() {
      this.dataFromFirstComponent = this.$route.query.response;
    },
  };
  </script>
  
  
  <style>
  .responseDataWrapper{
      padding: 100px 0px;
  }
  .linkback a{
      padding: 14px 14px;
      border: none;
      display: inline-block;
      text-decoration: none;
      text-transform: uppercase;
      border-radius: 0px;
      font-weight: 700;
      background: #003e78;
      color: #fff;
      transition: 0.3s ease-in-out;
      margin-top: 14px;
  }
  .linkback a:hover {
      border-radius: 5px;
      color: #fff;
      box-shadow: 0px 1px 22px -2px rgb(0 62 120 / 48%);
  }
  
  </style>